import React from "react";
import cx from "classnames";

import * as styles from "./container.module.css";

export const Container = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	return <div className={cx(styles.container, className)}>{children}</div>;
};
