import React, { useState } from "react";
import cx from "classnames";
import { Container } from "src/components/container";
import { BlockContent } from "src/components/block-content";

import * as styles from "./text-page.module.css";

interface TextPageProps {
	title: string;
	introCopy: any[];
	body: any[];
}

export const TextPage = ({
	data: { title, introCopy, body },
}: {
	data: TextPageProps;
}) => {
	return (
		<div className={styles.content}>
			<Container>
				<h1 className={cx("small-headline", styles.pageTitle)}>{title}</h1>
				<BlockContent
					className={cx("body", styles.introCopy)}
					blocks={introCopy}
				/>
				<BlockContent className={cx("small-body", styles.body)} blocks={body} />
			</Container>
		</div>
	);
};
