import React from "react";
import { graphql } from "gatsby";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { MetaInfo } from "src/types/meta";
import { TextPage } from "src/components/text-page";

export const query = graphql`
	query PageQuery($id: String!) {
		page: sanityTextPage(id: { eq: $id }) {
			content: _rawContent(resolveReferences: { maxDepth: 6 })
		}
	}
`;

export interface PageContent {
	main: {
		title: string;
		slug: {
			current: string;
		};
		introCopy: any[];
		body: any[];
	};
	meta: {
		metaTitle?: string;
		metaDescription?: string;
		openImage?: any;
		twitterImage?: any;
	};
}

export interface PageProps {
	data: {
		page: {
			content: PageContent;
		};
	};
	errors?: any;
	location: any;
	path: string;
}

const Page = ({ data, errors, location }: PageProps) => {
	const { main, meta } = data.page.content;

	// set fallback meta tags from content
	const metaInfo: MetaInfo = {
		metaTitle: meta?.metaTitle || main.title,
		metaDescription: meta?.metaDescription,
		openImage: meta?.openImage,
		twitterImage: meta?.twitterImage || meta?.openImage,
	};

	return (
		<RevealOnMount location={location}>
			<SEO
				metaInfo={metaInfo}
				pagePath={location.pathname}
				slug={main.slug.current}
			/>
			<TextPage data={main} />
		</RevealOnMount>
	);
};

export default Page;
