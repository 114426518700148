import React, { useState, useLayoutEffect, useEffect } from "react";
import cx from "classnames";

interface RevealOnMountProps {
  location: any;
  children: React.ReactNode;
}

export const RevealOnMount = ({ children }: RevealOnMountProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className={cx("fade-in-on-mount", isMounted ? "is-mounted" : "")}>
      {children}
    </div>
  );
};
